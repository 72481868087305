import Vue from "vue";
import TitleMixin from "@/mixins/title";
import SpinnerMixin from "@/mixins/spinner";
import OfficeEditTemplate from "@/components/Templates/Users/OfficeEditTemplate.vue";
import { CommandColumn, Edit, Sort, Toolbar } from "@syncfusion/ej2-vue-grids";
import { mapGetters } from "vuex";
import { Role } from "@/config/role";
export default Vue.extend({
    mixins: [TitleMixin, SpinnerMixin],
    data() {
        return {
            title: this.$t("users.create.title"),
            user: {},
            employees: [],
            roles: [],
            officesGrid: {
                editSettings: {
                    allowEditing: true,
                    allowAdding: true,
                    allowDeleting: true,
                    mode: "Dialog",
                    template: function () {
                        return {
                            template: Vue.component("office-user-item", OfficeEditTemplate)
                        };
                    }
                },
                commands: [
                    /*
                    {
                      type: "Edit",
                      buttonOption: { cssClass: "e-flat", iconCss: "e-edit e-icons" }
                    },
                    */
                    {
                        type: "Delete",
                        buttonOption: { cssClass: "e-flat", iconCss: "e-delete e-icons" }
                    }
                ],
                toolbar: ["Add"],
                sortOptions: {
                    columns: [{ field: "officeName", direction: "Ascending" }]
                },
                validationOptions: {
                    rules: {
                        officeId: {
                            required: true
                        }
                    }
                }
            },
            validationOptions: {
                rules: {
                    email: {
                        required: true,
                        email: true,
                        rangeLength: [3, 100]
                    },
                    firstName: {
                        required: true,
                        rangeLength: [1, 100]
                    },
                    lastName: {
                        required: true,
                        rangeLength: [1, 100]
                    },
                    roleId: {
                        required: true
                    }
                }
            }
        };
    },
    provide: {
        grid: [Sort, Edit, Toolbar, CommandColumn]
    },
    computed: {
        ...mapGetters({
            hasMultipleOffices: "account/hasMultipleOffices",
            getSingleOfficeId: "account/getSingleOfficeId"
        }),
        showOfficesGrid() {
            if (!this.hasMultipleOffices)
                return false;
            if (this.user.roleId == null)
                return false;
            if (this.user.roleId === Role.AdministratorId)
                return false;
            return true;
        }
    },
    methods: {
        officesGridActionBegin(args) {
            if (args.requestType === "save") {
                args.data["officeName"] = args.form.querySelector("#officeId").value;
            }
        },
        officesGridActionComplete(args) {
            if (args.requestType === "beginEdit" || args.requestType === "add") {
                // Add Validation Rules
                const userOffices = this.user.offices;
                args.form.ej2_instances[0].addRules("officeId", {
                    exists: [
                        function (args) {
                            const officeIdOld = args.element.form.querySelector("#officeIdOld").value;
                            if (args.value == officeIdOld)
                                return true;
                            return !userOffices.some(office => office.officeId == args.value);
                        },
                        this.$t("offices.officeExists")
                    ]
                });
                args.dialog.width = 380;
                if (args.requestType === "add") {
                    args.dialog.header = this.$t("offices.newOffice");
                }
                else {
                    args.dialog.header = this.$t("offices.editOffice", {
                        name: args.rowData["officeName"]
                    });
                }
            }
        },
        create() {
            return this.$store.dispatch("user/create", {
                user: this.user
            });
        },
        createSuccess() {
            this.$toast.showSuccessToast(this.$t("users.create.toastSuccessTitle"), this.$t("users.create.toastSuccessContent", {
                name: `${this.user.firstName} ${this.user.lastName}`
            }));
            return this.$router.push("/administration/users");
        },
        cancel() {
            return this.$router.push("/administration/users");
        }
    },
    async mounted() {
        this.showSpinner();
        try {
            const offices = await this.$store.dispatch("office/getAll", {
                load: true
            });
            this.employees = await this.$store.dispatch("employee/getAll", {
                load: true
            });
            this.employees.forEach((employee) => (employee.fullName = `${employee.name} ${employee.surname}`));
            this.roles = (await this.$store.dispatch("user/getRoles"));
            for (const role of this.roles)
                role.name = this.$t(role.name);
            await this.$store.dispatch("office/getAll", {
                load: true
            });
            this.user.offices = [];
            if (!this.hasMultipleOffices) {
                // Add the only office the user can set
                this.user.offices.push({
                    officeId: this.getSingleOfficeId,
                    officeName: ""
                });
            }
        }
        catch (errors) {
            this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("shared.toastFailureContent", {
                error: errors[0].message
            }));
            await this.$router.push("/administration/users");
        }
        finally {
            this.hideSpinner();
        }
    }
});
